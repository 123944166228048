<script setup lang="ts">
const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter
    text="¿Estás inscrito en Medicare?"
    @done="typewriterDone = true"
  />

  <ButtonGroup
    name="haveMedicare"
    :show="typewriterDone"
    :buttons="[
      {
        label: 'Sí',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ]"
  />
</template>
