<script setup lang="ts">
useHead({
  title: `Llama a ${brand.name} para saber más`,
})

const isPaidUser = ref(true)

onMounted(() => {
  isPaidUser.value = getSessionEnhanced().isPaidUser
})
</script>

<template>
  <Layout header="emily" class="container flex flex-col">
    <div class="flex-1 py-16">
      <Form
        id="healthyFoodsEmilyEs"
        :steps="[
          { id: 'medicare' },
          { id: 'us' },
          { id: 'medicaid' },
          { id: 'loading' },
          { id: 'call' },
        ]"
        product="medicare"
      />
    </div>
    <div class="h-px bg-gray-200"></div>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["boomer"],
    "smid": "MULTI-PLAN_EHWEB062302_M",
    "robots": "noindex"
  }
}
</route>
