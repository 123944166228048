<script setup lang="ts">
const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter
    text="Do you want $2,040 back on your Social Security Check?"
    @done="typewriterDone = true"
  />

  <ButtonGroup
    name="wantMoneyBack"
    :show="typewriterDone"
    :buttons="[
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ]"
  />
</template>
