<script setup lang="ts">
const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter
    text="¡Felicidades!"
    class="text-red font-bold"
    @done="typewriterDone = true"
  />

  <div
    :class="[
      typewriterDone ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-4',
      'transition ease-linear duration-300',
    ]"
  >
    <div class="text-xl sm:text-2xl text-center">
      Parece que puede haber planes con beneficios adicionales en tu área.
      Algunos planes incluyen
      <span class="text-red"> hasta $900 para usar en comestibles </span>
    </div>
  </div>

  <div
    :class="[
      typewriterDone ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-4',
      'transition ease-linear duration-300 delay-300',
    ]"
  >
    <div class="text-2xl sm:text-3xl text-center">
      Llama a la Línea de Ayuda ahora para hablar con un agente de seguros
      autorizado
    </div>
  </div>

  <div
    :class="[
      typewriterDone ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-4',
      'transition ease-linear duration-300 delay-600 space-y-6',
    ]"
  >
    <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />

    <div class="text-center text-gray-500"> Disponible L-V 9-7 EST </div>

    <div class="flex items-center justify-center space-x-4">
      <div class="relative flex w-4 h-4">
        <div
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
        >
        </div>
        <div class="relative inline-flex rounded-full h-4 w-4 bg-green-500">
        </div>
      </div>
      <div class="text-lg sm:text-xl font-medium">
        Agentes de ventas autorizados <br />
        están disponibles ahora
      </div>
    </div>
  </div>
</template>
