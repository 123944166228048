<script setup lang="ts">
const typewriterDone = ref(false)

const start = Date.now()
const now = useNow()

const timer = useDateFormat(
  computed(() => Math.max(start + 120000 - now.value.getTime(), 1000)),
  'mm:ss'
)
</script>

<template>
  <FormTitleTypewriter
    text="Congratulations!"
    class="text-red font-bold"
    @done="typewriterDone = true"
  />

  <div
    :class="[
      typewriterDone ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-4',
      'transition ease-linear duration-300',
    ]"
  >
    <div class="text-xl sm:text-2xl text-center">
      Looks like you may qualify for
      <span class="text-red"> additional benefits. </span>
    </div>
  </div>

  <div
    :class="[
      typewriterDone ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-4',
      'transition ease-linear duration-300 delay-300',
    ]"
  >
    <div class="text-2xl sm:text-3xl text-center">
      Call the {{ brand.name }} now! (Licensed insurance agents)
    </div>
  </div>

  <div
    :class="[
      typewriterDone ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-4',
      'transition ease-linear duration-300 delay-600 space-y-6',
    ]"
  >
    <ClickToCall :props="{ ctcLocation: 'last-step' }" tty />

    <div class="text-center text-gray-500"> Available M-F 8-9 EST </div>

    <div class="text-lg sm:text-xl font-medium text-center">
      Act quickly!
      <br />
      <span class="text-red"> {{ timer }} </span>
    </div>
  </div>
</template>
