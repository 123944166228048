<script setup lang="ts">
const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter
    text="Congratulations!"
    class="text-red font-bold"
    @done="typewriterDone = true"
  />

  <div
    :class="[
      typewriterDone ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-4',
      'transition ease-linear duration-300',
    ]"
  >
    <div class="text-xl sm:text-2xl text-center">
      We found plans with additional benefits in your area. You won't know until
      you call.
    </div>
  </div>

  <div
    :class="[
      typewriterDone ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-4',
      'transition ease-linear duration-300 delay-300',
    ]"
  >
    <div class="text-2xl sm:text-3xl text-center">
      Call the {{ brand.name }} now to speak to a licensed insurance agent
    </div>
  </div>

  <div
    :class="[
      typewriterDone ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-4',
      'transition ease-linear duration-300 delay-600 space-y-6',
    ]"
  >
    <ClickToCall
      :props="{ ctcLocation: 'healthy-foods-emily-last-step' }"
      tty
    />

    <div class="text-center text-gray-500"> Available M-F 9-7 EST </div>

    <div class="flex items-center justify-center space-x-4">
      <div class="relative flex w-4 h-4">
        <div
          class="animate-ping absolute inline-flex h-full w-full rounded-full bg-green-400 opacity-75"
        >
        </div>
        <div class="relative inline-flex rounded-full h-4 w-4 bg-green-500">
        </div>
      </div>
      <div class="text-lg sm:text-xl font-medium">
        Licensed sales agents <br />
        are standing by now
      </div>
    </div>
  </div>
</template>
