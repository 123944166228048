<script setup lang="ts">
const { onSubmit } = inject('form') as any

const { start } = useTimeoutFn(
  () => {
    onSubmit()
  },
  2000,
  {
    immediate: false,
  }
)
</script>

<template>
  <FormTitleTypewriter text="Revisando tus respuestas…" @done="start" />
</template>
