<script setup lang="ts">
useHead({
  title: 'Thank you - Your Health Insurance Quote Was Successful',
})

analytics.page({
  name: 'ACA TYP 1.0',
  category: 'TYP',
  product: 'aca',
})

const showCallNowModal = ref(true)
</script>

<template>
  <Layout header="simple">
    <div class="bg-darkBlue text-white">
      <div class="container py-16 flex flex-col items-center lg:flex-row">
        <img
          src="../../assets/confirmed-bro.svg"
          class="w-[460px] -mt-14 lg:-mb-14 lg:mr-8"
        />
        <div class="flex-1 text-center lg:text-left">
          <h1 class="text-5xl sm:text-6xl">
            You're <Underline>all set!</Underline>
          </h1>
          <div class="mt-6 text-2xl text-white text-opacity-75">
            A Licensed Health Insurance agent is calling you now.
          </div>
        </div>
      </div>
    </div>

    <Modal :open="showCallNowModal" @close="showCallNowModal = false">
      <ModalCallNow @close="showCallNowModal = false" />
    </Modal>
  </Layout>
</template>

<route lang="json">
{
  "meta": {
    "brands": ["boomer"],
    "robots": "noindex"
  }
}
</route>
