<script setup lang="ts">
const typewriterDone = ref(false)
</script>

<template>
  <FormTitleTypewriter
    :text="`Do you live in ${ip.lookup?.stateName || 'America'}?`"
    @done="typewriterDone = true"
  />

  <ButtonGroup
    name="liveInUs"
    :show="typewriterDone"
    :buttons="[
      {
        label: 'Yes',
        value: true,
      },
      {
        label: 'No',
        value: false,
      },
    ]"
  />
</template>
